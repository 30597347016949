import React from 'react';
import {cssName} from '../util';

const c = cssName('TextAreaField');
export default class TextAreaField extends React.Component {

  static defaultProps = {
    className: '',
    label: '',
    name: '',
    onBlur: () => {},
    onChange: () => {},
    onFocus: () => {},
    onInvalid: () => {},
    onValid: () => {},
    onValidate: () => true,
    placeholder: '',
    type: '',
    value: '',
    valid: true
  };

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.props.onValidate(this.props.value)
        ? this.props.onValid(this.props.value)
        : this.props.onInvalid(this.props.value);
    }
  }

  handleBlur = (e) => {
    if (!!e) e.persist();
    this.props.onBlur(e.target.value);
  };

  handleChange = (e) => {
    if (!!e) e.persist();
    this.props.onChange(e.target.value);
  };

  handleFocus = (e) => {
    if (!!e) e.persist();
    this.props.onFocus(e.target.value);
  };

  render = () =>
    <div className={`${c('root')} ${this.props.className} ${this.props.valid ? c('valid') : c('invalid')}`}>
      <label className={c('label')}>{this.props.label}</label>
      <textarea
        className={c('input')}
        name={this.props.name}
        placeholder={this.props.placeholder}
        onBlur={this.handleBlur}
        onChange={this.handleChange}
        onFocus={this.handleFocus}
        value={this.props.value}
      />
    </div>;
}