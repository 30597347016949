import React from 'react';
import {cssName} from '../util';

import Job from './Job';
import ExternalLink from './ExternalLink';

const c = cssName('Experience');
export default class Experience extends React.Component {

  static defaultProps = {
    className: '',
    content: {
      callToAction: {
        text: 'See more about my background on LinkedIn',
        link: 'https://www.linkedin.com/in/benjamin-alan-robinson-33a65251'
      },
      title: 'Experience',
      jobs: []
    }
  };

  render = () =>
    <div className={`${c('root')} ${this.props.className}`}>
      <h3 className={c('section-title')}>{this.props.content.title}</h3>
      <div className={c('list')}>
        <ul className={c('jobs')}>
          {this.props.content.jobs.map(s =>
            <li className={c('job')} key={JSON.stringify(s)}>
              <Job content={s} />
            </li>
          )}
        </ul>
      </div>
      <ExternalLink
        className={c('call-to-action')}
        link={this.props.content.callToAction.link}
        text={this.props.content.callToAction.text}
      />
    </div>;
}

