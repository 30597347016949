import React from 'react';
import {cssName} from '../util';

import Experience from './Experience';
import Education from './Education';
import Skills from './Skills';
import Button from './Button';
import ExternalLink from './ExternalLink';

const c = cssName('Resume');
export default class Resume extends React.Component {

  static defaultProps = {
    className: '',
    selfRef: null,
    content: {}
  };

  constructor(props) {
    super(props);
  }

  // <ExternalLink
  //             className={c('download')}
  //             link={this.props.content.download}
  //             text={'Download Resume (PDF)'}
  //           />

  render = () =>
    <div className={`${c('root')} ${this.props.className}`} ref={this.props.selfRef}>
      <div className={c('sections')}>
        <div className={`${c('intro')} ${c('section')}`}>
          <p className={c('summary')}>{this.props.content.summary}</p>
        </div>
        <Experience
          className={`${c('experience')} ${c('section')}`}
          content={this.props.content.experience}
        />
        <Skills
          className={`${c('skills')} ${c('section')}`}
          content={this.props.content.skills}
        />
        <Education
          className={`${c('education')} ${c('section')}`}
          content={this.props.content.education}
        />
      </div>
    </div>;
}