import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

/**
 * Returns a function for scoped css naming.
 *
 * @param {string} component
 * @returns {function}
 */
export function cssName(component) {
  return (className, modifiers = []) => {
    if (modifiers.length > 0) {
      return `${component}--${className} ${component}--${className}-${modifiers.join('-')}`
    } else {
      return `${component}--${className}`;
    }
  }
}

/**
 * Returns a function to do the scroll action.
 *
 * @param ref
 * @returns {function(): void}
 */
export const scrollToRef = (ref) => () => ref.current.scrollIntoView({ behavior: 'smooth', block: 'start'});

/**
 * Loads an image asynchronously with an optional minimum delay
 *
 * @param src
 * @param ms
 * @param callback
 */
export const lazyImageWithDelay = (src, callback, ms = 0) => {
  const startTime = Date.now();
  const image = new Image();
  image.addEventListener('load', () => {
    const endTime = Date.now();
    const remaining = ms - (endTime - startTime);
    setTimeout(() => callback(src), remaining > 0 ? remaining : 0)
  });
  image.src = src;
};