import React from 'react';

import Header from './Header';
import Resume from './Resume';
import Footer from './Footer';
import {cssName, scrollToRef} from '../util';

import content from '../assets/data/content.json';

const c = cssName('Page');
export default class Page extends React.Component {

  static defaultProps = {
    content: content
  };

  constructor(props) {
    super(props);
    this.headerRef = React.createRef();
    this.resumeRef = React.createRef();
    this.footerRef = React.createRef();
  }

  render = () =>
    <div className={c('root')}>
      <Header
        className={c('header')}
        content={this.props.content.header}
        onContactClick={scrollToRef(this.footerRef)}
        onResumeClick={scrollToRef(this.resumeRef)}
        selfRef={this.headerRef}
      />
      <Resume
        className={c('resume')}
        content={this.props.content.resume}
        selfRef={this.resumeRef}
      />
      <Footer
        className={c('footer')}
        content={this.props.content.footer}
        onBackToTopClick={scrollToRef(this.headerRef)}
        selfRef={this.footerRef}
      />
    </div>;
}