import React from 'react';
import {cssName} from '../util';

const c = cssName('ExternalLink');
export default class ExternalLink extends React.Component {

  static defaultProps = {
    className: '',
    link: '',
    text: ''
  };

  render = () =>
    <a
      className={`${c('root')} ${this.props.className}`}
      href={this.props.link}
      rel={'nofollow noopener'}
      target={'_blank'}
    >
      {this.props.text}
    </a>;

}