import React from 'react';
import {cssName} from '../util';

const c = cssName('School');
export default class School extends React.Component {

  static defaultProps = {
    className: '',
    content: {
      degree: '',
      school: '',
      years: ''
    }
  };

  render = () =>
    <div className={`${c('root')} ${this.props.className}`}>
      <p className={c('dates')}>{this.props.content.years}</p>
      <h4 className={c('title')}>
        <span className={c('degree')}>{this.props.content.degree}</span>
        {' from '}
        <span className={c('school')}>{this.props.content.school}</span>
      </h4>
    </div>;
}