import React from 'react';
import {cssName} from '../util';

import ExternalLink from './ExternalLink';

const c = cssName('Skills');
export default class Skills extends React.Component {
  
  static defaultProps = {
    content: {
      callToAction: {
        text: 'See my public repos on Github',
        link: 'https://github.com/pipbeard'
      },
      frameworks: [],
      languages: [],
      title: 'Skills'
    }
  };

  constructor(props) {
    super(props);
  }
  
  render = () =>
    <div className={`${c('root')} ${this.props.className}`}>
      <h3 className={c('section-title')}>{this.props.content.title}</h3>
      <div className={c('lists')}>
        <div className={c('list')}>
          <h4 className={c('title')}>{'Languages'}</h4>
          <ul className={`${c('languages')} ${c('ul')}`}>
            {this.props.content.languages.map(s =>
              <li className={c('skill')} key={s}>{s}</li>
            )}
          </ul>
        </div>
        <div className={c('list')}>
          <h4 className={c('title')}>{'Frameworks'}</h4>
          <ul className={`${c('languages')} ${c('ul')}`}>
            {this.props.content.frameworks.map(s =>
              <li className={c('skill')} key={s}>{s}</li>
            )}
          </ul>
        </div>
      </div>
      <ExternalLink
        className={c('call-to-action')}
        link={this.props.content.callToAction.link}
        text={this.props.content.callToAction.text}
      />
    </div>;
  
}