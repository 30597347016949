import React from 'react';
import {cssName} from '../util';

const c = cssName('Job');
export default class Job extends React.Component {

  static defaultProps = {
    className: '',
    content: {
      title: '',
      company: '',
      years: ''
    }
  };

  render = () =>
    <div className={`${c('root')} ${this.props.className}`}>
      <p className={c('dates')}>{this.props.content.years}</p>
      <h4 className={c('title')}>
        <span className={c('name')}>{this.props.content.title}</span>
        {' at '}
        <span className={c('company')}>{this.props.content.company}</span>
      </h4>
    </div>;
}