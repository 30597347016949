import React from 'react';
import {cssName} from '../util';

import School from './School';

const c = cssName('Education');
export default class Education extends React.Component {

  static defaultProps = {
    className: '',
    content: {
      title: 'Experience',
      schools: []
    }
  };

  render = () =>
    <div className={`${c('root')} ${this.props.className}`}>
      <h3 className={c('section-title')}>{this.props.content.title}</h3>
      <div className={c('list')}>
        <ul className={`${c('schools')} ${c('ul')}`}>
          {this.props.content.schools.map(s =>
            <li className={c('school')} key={JSON.stringify(s)}>
              <School content={s} />
            </li>
          )}
        </ul>
      </div>
    </div>
}