import React from 'react';
import {Map, Set} from 'immutable';
import {cssName} from '../util';
import TextField from './TextField';
import TextAreaField from './TextAreaField';
import Button from './Button';

const c = cssName('EmailForm');
const fieldNames = {
  name: 'name',
  email: 'email',
  message: 'message',
  no: 'no'
};

export default class EmailForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      submitted: false,
      inputs: new Map(),
      invalidInputs: new Set()
    }
  }

  handleSetField = (field) => (value) =>
    this.setState({inputs: this.state.inputs.set(fieldNames[field], value)});

  handleInvalidField = (field) => () =>
    this.setState({invalidInputs: this.state.invalidInputs.add(field)});

  handleValidField = (field) => () =>
    this.setState({invalidInputs: this.state.invalidInputs.remove(field)});

  handleSubmit = () => {
    this.setState({submitting: true}, () => setTimeout(() => {
      this.setState({submitted: true}, () => setTimeout(() => {
        this.setState({submitted: false, submitting: false})
      }, 1500));
    }, 3000))
  };

  makeButtonText = () => {
    if (this.state.submitting && !this.state.submitted) {
      return 'Sending...';
    } else if (this.state.submitted) {
      return 'Sent!';
    } else {
      return 'Send Message';
    }
  };

  render = () => {
    const nonSubmittable = this.state.submitted ||
      this.state.submitting ||
      this.state.invalidInputs.size > 0 ||
      this.state.inputs.size < 3;

    const buttonText = this.makeButtonText();

    return <div className={`${c('root')} ${this.props.className} ${this.state.submitting ? c('submitting') : ''}`}>
      <div className={c('inputs')}>
        <div className={c('group')}>
          <TextField
            className={c('field')}
            label={'Full Name'}
            name={fieldNames.name}
            onChange={this.handleSetField(fieldNames.name)}
            onInvalid={this.handleInvalidField(fieldNames.name)}
            onValid={this.handleValidField(fieldNames.name)}
            onValidate={(v) => /^[a-zA-Z\s]+$/.test(v)}
            placeholder={'Your Name'}
            valid={!this.state.invalidInputs.has(fieldNames.name)}
            value={this.state.inputs.get(fieldNames.name)}
          />
          <TextField
            className={c('field')}
            label={'Email'}
            name={fieldNames.email}
            onChange={this.handleSetField(fieldNames.email)}
            onValid={this.handleValidField(fieldNames.email)}
            onInvalid={this.handleInvalidField(fieldNames.email)}
            onValidate={(v) => /[a-z0-9\.\-\_]+\@[a-z0-9\.\-\_]+\.[a-z\.]+/.test(v)}
            placeholder={'you@email.com'}
            valid={!this.state.invalidInputs.has(fieldNames.email)}
            value={this.state.inputs.get(fieldNames.email)}
          />
        </div>
        <div className={c('group')}>
          <TextAreaField
            className={c('field')}
            label={'Message'}
            name={fieldNames.message}
            onChange={this.handleSetField(fieldNames.message)}
            valid
            value={this.state.inputs.get(fieldNames.message)}
          />
        </div>
      </div>
      <div className={c('group')}>
        <Button
          className={`${c('button')} ${this.state.submitted ? c('button-sent') : ''}`}
          disabled={nonSubmittable}
          onClick={this.handleSubmit}
          text={buttonText}
        />
      </div>
    </div>;
  }
}