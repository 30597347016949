import React from 'react';
import {cssName} from '../util';

const c = cssName('Button');
export default class Button extends React.Component {

  static defaultProps = {
    className: '',
    disabled: false,
    text: '',
    onClick: () => {}
  };

  constructor(props) {
    super(props);
  }

  handleClick = (e) => {
    if (!!e) e.persist();
    this.props.onClick();
  };

  render = () =>
    <button
      className={`${c('root')} ${this.props.className}`}
      disabled={this.props.disabled}
      onClick={this.handleClick}
    >
      <span className={c('text')}>{this.props.text}</span>
    </button>;

}