import React from 'react';
import {cssName} from '../util';
import EmailForm from './EmailForm';
import ExternalLink from './ExternalLink';

const c = cssName('Footer');
export default class Footer extends React.Component {

  static defaultProps = {
    className: '',
    content: {
      email: 'ben.a.robinson@gmail.com',
      github: 'https://github.com/pipbeard',
      instagram: 'https://www.instagram.com/benjaminarobinson',
      linkedin: 'https://www.linkedin.com/in/benjamin-alan-robinson-33a65251',
      medium: 'https://medium.com/@benjaminrobinson',
      title: 'Contact me'
    },
    selfRef: null
  };

  render = () =>
    <footer className={`${c('root')} ${this.props.className}`} ref={this.props.selfRef}>
      <div className={c('container')}>
        <h3 className={c('title')}>{this.props.content.title}</h3>
        <ExternalLink
          className={c('email')}
          link={`mailto:${this.props.content.email}`}
          text={'Send me an email'}
        />
        <ul className={c('ul')}>
          {this.props.content.socials.map((social, i) =>
            <li className={c('item')} key={JSON.stringify(social) + i}>
              <ExternalLink className={c('link')} link={social.link} text={social.text} />
            </li>
          )}
        </ul>
        <div className={c('copyright')}>{`\u00A9 ${new Date().getFullYear()} Benjamin Alan Robinson`}</div>
      </div>
    </footer>;
}