import React from 'react';
import {cssName, lazyImageWithDelay} from '../util';
import background from '../assets/images/painting-seamless.jpg';

import Button from './Button';

const c = cssName('Header');
export default class Header extends React.Component {

  static defaultProps = {
    className: '',
    content: {
      contactLinkText: 'Contact',
      company: {
        name: 'ValuePenguin',
        link: 'https://www.valuepenguin.com'
      },
      resumeLinkText: 'Résumé',
      subtitle: 'Software Engineer',
      title: 'Benjamin Alan Robinson'
    },
    onContactClick: () => {},
    onResumeClick: () => {},
    selfRef: null
  };

  constructor(props) {
    super(props);
    this.state = {
      actionsFadedIn: false,
      backgroundFadeIn: false,
      titlesFadedIn: false,
      backgroundImageStyle: {},
      backgroundPositionY: '0px'
    };
  }

  setBackgroundImage = (src) => {
    this.setState({
      backgroundFadeIn: true,
      backgroundImageStyle: {
        backgroundImage: `url('${src}')`
      }
    });
  };

  componentDidMount = () => {
    setTimeout(() => this.setState({titlesFadeIn: true}), 500);
    setTimeout(() => this.setState({actionsFadeIn: true}), 1000);
    lazyImageWithDelay(background, this.setBackgroundImage, 1500);

    window.addEventListener('scroll', () => {
      const distance = window.scrollY / 6;
      this.setState({
        backgroundPositionY: `-${distance}px`
      });
    });
  };

  maybeRenderCompany = () => this.props.content.company && this.props.content.company.name && this.props.content.company.link ?
    <span className={c('company')}>
      {', currently at '}
      <a
        className={c('company-link')}
        href={this.props.content.company.link}
        target={'_blank'}
        rel={'nofollow noopener'}
      >{this.props.content.company.name}</a>
    </span> :
    null;

  render = () =>
    <header ref={this.props.selfRef} className={c('root')}>
      <div
        className={`${c('background')} ${this.state.backgroundFadeIn ? c('fade-in') : ''}`}
        style={Object.assign({}, this.state.backgroundImageStyle, {backgroundPositionY: this.state.backgroundPositionY})}
      />
      <div className={c('container')}>
        <div className={`${c('titles')} ${this.state.titlesFadeIn ? c('fade-in') : ''}`}>
          <h1 className={c('title')}>{this.props.content.title}</h1>
          <h2 className={c('subtitle')}>
            <span className={c('job')}>{this.props.content.subtitle}</span>
            {this.maybeRenderCompany()}
          </h2>
        </div>
        <div className={`${c('actions')} ${this.state.actionsFadeIn ? c('fade-in') : ''}`}>
          <Button
            className={c('action-contact')}
            text={this.props.content.contactLinkText}
            onClick={this.props.onContactClick}
          />
          <Button
            className={c('action-resume')}
            text={this.props.content.resumeLinkText}
            onClick={this.props.onResumeClick}
          />
        </div>
      </div>
    </header>;
}

